<template>
  <van-tabs class="type-tabs" v-model:active="activeTab" type="card" color="#4983FE">
    <van-tab title="免费券" name="1">
      <free-bind></free-bind>
    </van-tab>
    <van-tab title="优惠券" name="0">
      <coupon-list></coupon-list>
    </van-tab>
  </van-tabs>
</template>
<script>
import {Tab, Tabs} from "vant";

export default {
  name: "ReceiveCenter",
  components:{
    [Tab.name]:Tab,
    [Tabs.name]:Tabs,
  }
}
</script>
<script setup>
import {ref} from "vue";
import FreeBind from "@/views/personal/Card/FreeBind.vue";
import CouponList from "@/views/personal/Card/Coupon.vue";
const activeTab = ref("1")
</script>
<style scoped>
.type-tabs{
  margin-top: 12px;
}
.type-tabs :deep(.van-tabs__wrap){
  height:30px !important;
  background: none !important;
}
.type-tabs :deep(.van-tab--active) {
  font-weight: normal !important;
  color: #FFFFFF !important;
}
</style>