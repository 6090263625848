<template>
  <van-list class="coupon-list" v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getFreeBindList">
    <van-cell v-for="(item, key) in list" :key="key" class="coupon-cell" :class="item.received < 1 ? 'receiving' : 'received'" clickable>
      <template #title>
        <div class="coupon-title">
          <div class="coupon-num van-ellipsis">￥<span>{{ Math.floor(item.amount) }}</span></div>
          <div class="van-multi-ellipsis--l2 coupon-text">{{ Math.floor(item.amount) }}元代金券</div>
        </div>
      </template>
      <template #value>
        <div class="van-multi-ellipsis--l2 coupon-rule"><van-tag round type="primary">代金券</van-tag>{{ item.name }}</div>
        <div class="van-multi-ellipsis--l2 coupon-date">使用有效期:
          <span>{{item.exp_type == 1 ? (date(item.exp_start, 'Y-m-d') + '至' + item.exp_end ? date(item.exp_end, 'Y-m-d') +'到期' : '长期有效') : '领取后当日有效'}}</span>
        </div>
      </template>
      <template #right-icon>
        <div v-if="item.received < 1" class="coupon-button" @click="onReceive(item,key)">立即领取</div>
        <div v-else class="received-icon"></div>
      </template>
    </van-cell>
  </van-list>
</template>
<script>
import {Cell, List, Tag, Toast} from "vant";
import {reactive, ref} from "vue";
import {freeBindList, receiveFreeBind} from "@/api/free_bind";
import {date} from "@/utils/function";
export default {
  name: "FreeBind",
  components: {
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [List.name]: List,
  },
  setup() {
    const list = ref([])
    const query = reactive({
      last:0,
      limit:10
    })
    const loading = ref(false);
    const finished = ref(false);
    const getFreeBindList = ()=>{
      loading.value = true;
      let oldList = list.value;
      freeBindList(query).then(({data})=>{
        loading.value = false;
        list.value = [...oldList,...data.list];
        query.last = data.last
        if(data.list.length < query.limit || data.last == 0){
          finished.value = true;
        }
      })
    }
    const onReceive = (item,index) =>{
      receiveFreeBind(item.id).then(() => {
        Toast('领取成功')
        list.value[index].received = 1
      })
    }
    return {
      list,
      loading,
      finished,
      getFreeBindList,
      onReceive,
      date,
    };
  },
}
</script>
<style scoped>
.coupon-list {
  padding: 12px 15px;
  background: none;
}

.coupon-cell {
  padding: 0px;
  background: url("../../../assets/images/coupon/value-coupon.png") right no-repeat;
  background-size: calc(100% - 140px) 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
  border-radius: 20px;
  overflow: hidden;
  align-items: center;
}
.coupon-cell::after{
  display: none;
}
.coupon-cell.receiving::after{
  display: block;
  border:none;
  transform: none;
  right:36px;
  left: unset;
  border-left:1px dashed #DCDCDC;
  top:0px;
}
.coupon-cell :deep(.van-cell__title) {
  width:140px;
  background: url("../../../assets/images/coupon/blue-coupon.png") center no-repeat;
  background-size: 100% 100%;
  flex: unset;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 10px 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.coupon-title {
  width: 100%;
}

.coupon-num {
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
}

.coupon-num span {
  font-size:30px;
}

.coupon-text {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

.coupon-cell :deep(.van-cell__value) {
  text-align: left;
  padding: 12px;
}
.coupon-cell.received :deep(.van-cell__value){
  padding-right:56px;
}
.coupon-rule {
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}

.coupon-date {
  font-size: 11px;
  font-weight: 400;
  color: #4E5969;
  line-height: 16px;
  margin-top: 8px;
}
.coupon-date span{
  opacity: 0.8;
}
.coupon-button{
  width:36px;
  padding:0px 12px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #4983FE;
}
.received-icon{
  width:50px;
  height:50px;
  position: absolute;
  top:0px;
  right:0px;
  background: url("../../../assets/images/coupon/received-tag.png") center no-repeat;
  background-size: auto 100%;
}
</style>