<template>
  <van-list class="bind-list" v-model:loading="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="getBindList">
    <van-cell v-for="(item, key) in list" :key="key" class="bind-cell" :class="item.received < 1 ? 'receiving' : 'received'" clickable>
      <template #title>
        <div class="bind-title">
          <div class="van-ellipsis bind-num">￥<span>{{ Math.floor(item.money) }}</span></div>
          <div class="van-multi-ellipsis--l2 bind-text">{{ Math.floor(item.send_money) }}元代金券</div>
        </div>
      </template>
      <template #value>
        <div class="van-multi-ellipsis--l2 bind-rule"><van-tag round type="primary">代金券</van-tag>{{ item.game_name }}</div>
        <div class="van-multi-ellipsis--l2 bind-date">{{ item.expire_time ? date(item.expire_time, 'Y-m-d H:i:s') + '到期' : '长期有效' }}
        </div>
      </template>
    </van-cell>
  </van-list>
</template>

<script>
import {Cell, NavBar,Tag,List} from "vant";
import {ref,reactive} from "vue";
import {bindList} from "@/api/user_balance";
import {date} from "@/utils/function";
import "@/style/common.css"

export default {
  name: "UserBind",
  components: {
    [Tag.name]: Tag,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [List.name]: List,
  },
  setup() {
    const list = ref([])
    const query = reactive({
      last:0,
      limit:10
    })
    const loading = ref(false);
    const finished = ref(false);
    const getBindList = ()=>{
      loading.value = true;
      let oldList = list.value;
      bindList(query).then(({data})=>{
        loading.value = false;
        list.value = [...oldList,...data.list];
        query.last = data.last
        if(data.list.length < query.limit || data.last == 0){
          finished.value = true;
        }
      })
    }

    return {
      list,
      loading,
      finished,
      getBindList,
      date,
    };
  },
}
</script>

<style scoped>
.bind-list {
  padding: 12px 15px;
  background: none;
}

.bind-cell {
  padding: 0px;
  background: url("../../../assets/images/coupon/value-coupon.png") right no-repeat;
  background-size: calc(100% - 140px) 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
  border-radius: 20px;
  overflow: hidden;
  align-items: center;
}
.bind-cell::after{
  display: none;
}
.bind-cell :deep(.van-cell__title) {
  width: 140px;
  background: url("../../../assets/images/coupon/blue-coupon.png") center no-repeat;
  background-size: 100% 100%;
  flex: unset;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 10px 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bind-title {
  width: 100%;
}

.bind-num {
  font-size: 14px;
  line-height: 40px;
}

.bind-num span {
  font-size:30px;
}

.bind-text {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

.bind-cell :deep(.van-cell__value) {
  text-align: left;
  padding: 12px;
}

.bind-rule {
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}

.bind-date {
  font-size: 11px;
  font-weight: 400;
  color: #4E5969;
  line-height: 16px;
  margin-top: 8px;
}
</style>
