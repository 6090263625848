//免费券列表
import request from "@/utils/request";

export function freeBindList(data){
    return request({
        method: 'post',
        url: '/free-bind/list',
        data,
    })
}
//领取优惠券
export function receiveFreeBind(free_bind_id){
    return request({
        method: 'post',
        url: '/free-bind/receive',
        data:{free_bind_id},
    })
}