<template>
<div class="main gray-bg">
  <van-nav-bar class="top-bar" title="卡券" left-arrow @click-left="onClickLeft"/>
  <van-tabs class="card-tabs" v-model:active="activeTab">
    <van-tab title="代金券" name="0">
      <user-bind></user-bind>
    </van-tab>
    <van-tab title="领券中心" name="1">
      <receive-center></receive-center>
    </van-tab>
  </van-tabs>
</div>
</template>

<script>
import {NavBar, Tab, Tabs} from "vant";

export default {
  name: "VoucherCard",
  components:{
    [NavBar.name]:NavBar,
    [Tab.name]:Tab,
    [Tabs.name]:Tabs,
  }
}
</script>
<script setup>
import {ref} from "vue";
import "@/style/common.css"
import {exitPage} from "@/utils/callback";
import ReceiveCenter from "@/views/personal/Card/ReceiveCenter.vue";
import UserBind from "@/views/personal/Card/UserBind.vue";

const activeTab = ref("1")
//返回操作
const onClickLeft = () => {
  exitPage()
};
</script>

<style scoped>
.main{
  height:100%;
}
.card-tabs{
  height:calc(100% - 44px);
  overflow: hidden;
}
.card-tabs :deep(.van-tabs__wrap){
  position: sticky;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px 0px #EEEEEE;
}
.card-tabs :deep(.van-tab--line){
  font-size: 16px;
  font-weight: 400;
  color: #4E5969;
}
.card-tabs :deep(.van-tab--active){
  font-weight: 600;
  color: #4983FE;
}
.card-tabs :deep(.van-tabs__nav--line){
  box-sizing: border-box;
  padding-bottom:0px;
}
.card-tabs :deep(.van-tabs__line){
  width: 24px;
  height: 4px;
  background: #4983FE;
  bottom:8px;
}
.card-tabs :deep(.van-tabs__content){
  height:calc(100% - 50px);
}
.card-tabs :deep(.van-tab__panel){
  height: 100%;
  overflow: auto;
}
</style>
